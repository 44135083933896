<template>
	<div class="order">

		<div class="order__content">
			<div class="order__video">
				<video v-if="isMD" autoplay muted loop playsinline class="anim-fade-in">
					<source src="@/assets/home/intro/Schastye_1980х1100.mp4" type="video/mp4">
				</video>
				<video v-else autoplay muted loop playsinline class="anim-fade-in">
					<source src="@/assets/home/intro/Schastye_640х1280.mp4" type="video/mp4">
				</video>
			</div>

			<div class="order__text">
				<v-container>
					<v-row>
						<v-col cols="12" class="text-center">

							<div>
								<div class="mt-15">
									<svgicon class="order__logo" name="logo-white" original/>
									<h1 class="mt-8 d-flex align-center justify-center"><span class="dot white"></span>{{order.ID}}<span
											class="dot white"></span></h1>
									<p>Ваш номер заказа</p>
								</div>

								<h1 class="my-8">Спасибо за ваш заказ!</h1>
								<template v-if="withPayment">
									<p class="mt-4">Перенаправляем на страницу оплаты...</p>

									<v-progress-circular class="mt-8"
														 indeterminate
														 color="white"/>
								</template>
								<template v-else>
<!--									<p class="mt-4 mb-8">Мы уже готовим ваши прекрасные конфеты!</p>-->
									<btn-link :to="{name:'shop'}">Новые покупки</btn-link>
								</template>
							</div>

						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>

	</div>
</template>

<script>
	import vp from "@/mixins/vp";
	import BtnLink from "@/components/btn-link";

	export default {
		name: "order",
		mixins: [vp],
		components: {BtnLink},
		data() {
			return {
				//withPayment: false
			}
		},
		computed: {
			withPayment() {
				return !!this.paymentUrl;
			},
			paymentUrl() {
				return this.$store.state.page?.OrderController?.alfaBank?.paymentUrl;
				//return this.$store.state.page?.OrderController?.stripe?.url;
			},
			order() {
				return this.$store.state.page?.OrderController?.order;
			},
		},
		mounted() {
			if (this.paymentUrl) {
				setTimeout(() => {
					self.location.href = this.paymentUrl;
				}, 3000);
			}
		}
	}
</script>

<style lang="scss">
	.order {

		&__content {
			position: relative;
			height: 640px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $black;
		}

		&__video {
			//width: 320px;
			//margin: 0 auto;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.4;

			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				//mix-blend-mode: lighten;
				//animation: fade-in 2s forwards;
			}
		}

		&__text {
			position: relative;
			z-index: 1;

			p, span {
				color: $white;
			}
		}

		&__logo {
			width: 128px;
			height: 128px;
		}
	}
</style>
